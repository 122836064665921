import './scripts/main';
import './scripts/forms';
import { setupCardDeckSwiperForMobile } from './scripts/swipers';
import './scripts/spots';

if (document.readyState !== 'loading') {
  const event = document.createEvent('Event');
  event.initEvent('DOMContentLoaded', true, true);
  window.document.dispatchEvent(event);
}

setupCardDeckSwiperForMobile('#cases-swiper');
