import Konva from 'konva';

import { isMobile, isTablet, setupResponsiveCallback } from './utils';

let stage, layer;
let spots = {};
let containerDimensions;
let isReboundingFromSpot = false;
let distance = {};
let pointerPosition = { x: 0, y: 0 };
let distanceToPointer = {};
let isReboundingFromPointer = { A: false, B: false };
let pulseAnimation = {};
let moveAnimation = {};

const container = document.querySelector('#index-hero-section .container');
const DEFAULT_SPOT_DIMENSIONS = {
  A: { width: 768, height: 670 },
  B: { width: 787, height: 668 },
}
const PULSE_FREQUENCY = 10000;
const PULSE_FACTOR = 0.1;
const BASE_VELOCITY = 0.05;
const ACCELERATION_FACTOR = 0.05;
const STAGE_REBOUND_THRESHOLD = 350;
const SPOT_REBOUND_THRESHOLD = 450;
const MOUSE_REBOUND_THRESHOLD = 500;
const REBOUND_THROTTLE = 1500;
const POINTER_REBOUND_THROTTLE = 1000;
const NO_REBOUND = { x: false, y: false };

const SVG_SPOTS = {
  A: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3NjgiIGhlaWdodD0iNjcwIiB2aWV3Qm94PSIwIDAgNzY4IDY3MCI+CiAgICA8ZGVmcz4KICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSIxOC43MzUlIiB4Mj0iNjMuNTg4JSIgeTE9IjY0LjkyNCUiIHkyPSIzMS43MjclIj4KICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZFMzRDOSIvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyNkIwOUEiIHN0b3Atb3BhY2l0eT0iLjc5OSIvPgogICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICAgICAgPGZpbHRlciBpZD0iYiIgd2lkdGg9IjE1NC42JSIgaGVpZ2h0PSIxNzEuMSUiIHg9Ii0yNy4zJSIgeT0iLTM1LjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSI1MCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPHBhdGggZmlsbD0idXJsKCNhKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEwLjY5NiA0MjBDNzEuNzIgNTI3Ljk2IDE0NC4yNDIgNjI1LjAzIDI0Ny42MDcgNjQwLjkxNmM4MC41ODMgMTIuMzg2IDE4Mi4wNTEtMTEuODY4IDIyNC44NTUtODQuOTY3IDQwLjk3NC02OS45NzQgMTY4LjYyNC04Mi4wNzIgMTc1Ljg4Ny0xNjAuOTQgNi45OTUtNzUuOTctNDMuMjYtMTQ5LjkxOC0xMDYuOTMxLTE2NC45MzctNDguMjY3LTExLjM4NS0yMTIuNjg2LTE2Ljk0LTI5Mi44MTEgMjYuOTktNjguMiAzNy4zOTItMTA3LjkxIDc5LjgzNC0xMzcuOTExIDE2Mi45Mzh6IiBmaWx0ZXI9InVybCgjYikiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTk4KSIvPgo8L3N2Zz4K',
  B: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3ODciIGhlaWdodD0iNjY4IiB2aWV3Qm94PSIwIDAgNzg3IDY2OCI+CiAgICA8ZGVmcz4KICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSI1MCUiIHgyPSI1MCUiIHkxPSIxLjU0NyUiIHkyPSI4Mi41NzUlIj4KICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzM0NzBDOSIvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9Ijk4LjkzOSUiIHN0b3AtY29sb3I9IiMyNkIwOUEiIHN0b3Atb3BhY2l0eT0iLjc5OSIvPgogICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICAgICAgPGZpbHRlciBpZD0iYiIgd2lkdGg9IjE1NC42JSIgaGVpZ2h0PSIxNzEuMSUiIHg9Ii0yNy4zJSIgeT0iLTM1LjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0iU291cmNlR3JhcGhpYyIgc3RkRGV2aWF0aW9uPSI1MCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPHBhdGggZmlsbD0idXJsKCNhKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTI0MS42OTYgNTExYy0zOC45NzUgMTA3Ljk2IDMzLjU0NiAyMDUuMDI5IDEzNi45MTEgMjIwLjkxNiA4MC41ODMgMTIuMzg2IDE4Mi4wNTEtMTEuODY4IDIyNC44NTUtODQuOTY3IDQwLjk3NC02OS45NzQgMTY4LjYyNC04Mi4wNzIgMTc1Ljg4Ny0xNjAuOTQgNi45OTUtNzUuOTctNDMuMjYtMTQ5LjkxOC0xMDYuOTMxLTE2NC45MzctNDguMjY3LTExLjM4NS0yMTIuNjg2LTE2Ljk0LTI5Mi44MTEgMjYuOTktNjguMiAzNy4zOTItMTA3LjkxIDc5LjgzNC0xMzcuOTExIDE2Mi45Mzh6IiBmaWx0ZXI9InVybCgjYikiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTEyIC0xODkpIi8+Cjwvc3ZnPgo='
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomDirection() {
  const direction = Math.random() > 0.5 ? 1 : -1;
  return direction * getRandomArbitrary(0.5, 1.5);
}

function getScaleFactor() {
  if (isMobile()) {
    return 0.5;
  }
  if (isTablet()) {
    return 0.75;
  }

  return 1;
}

function getSpotDimensions(spot) {
  const scaleFactor = getScaleFactor();
  return {
    width: DEFAULT_SPOT_DIMENSIONS[spot].width * scaleFactor,
    height: DEFAULT_SPOT_DIMENSIONS[spot].height * scaleFactor,
  }
}

function getContainerDimensions() {
  const xStart = (stage.attrs.width - container.offsetWidth) / 2;
  const yStart = (stage.attrs.height - container.offsetHeight) / 2;

  return {
    xStart,
    xEnd: xStart + container.offsetWidth,
    yStart,
    yEnd: yStart + container.offsetHeight
  }
}

function getSpotPosition(spot) {
  const getPercentX = (fr) => container.offsetWidth * fr;
  const getPercentY = (fr) => container.offsetHeight * fr;

  if (spot === 'A') {
    if (isMobile()) {
      return {
        x: containerDimensions.xStart - getPercentX(0.6),
        y: containerDimensions.yStart + getPercentY(0.15)
      }
    }

    return {
      x: containerDimensions.xStart - getPercentX(0.2),
      y: containerDimensions.yStart,
    }
  }

  const { width, height } = getSpotDimensions(spot);

  if (isMobile()) {
    return {
      x: containerDimensions.xEnd + getPercentX(0.5) - width,
      y: containerDimensions.yEnd + getPercentY(0.2) - height,
    }
  }

  return {
    x: containerDimensions.xEnd + getPercentX(0.2) - width,
    y: containerDimensions.yEnd + getPercentY(0.05) - height,
  }
}

function getDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(Math.abs(x1 - x2), 2) + Math.pow(Math.abs(y1 - y2), 2));
}

function calculateStageRebound(spot) {
  if (!spots[spot]) {
    return NO_REBOUND;
  }
  const x = spots[spot].getX();
  const y = spots[spot].getY();

  return {
    x: x < 0 || x > stage.getWidth(),
    y: y < 0 || y > stage.getHeight()
  }
  // return {
  //   x: x < STAGE_REBOUND_THRESHOLD || x > stage.getWidth() - STAGE_REBOUND_THRESHOLD,
  //   y: y < STAGE_REBOUND_THRESHOLD || y > stage.getHeight() - STAGE_REBOUND_THRESHOLD
  // }
}

function calculateSpotRebound(spot) {
  if (!spots || !spots[spot]) {
    return false;
  }
  const otherSpot = spot === 'A' ? spots['B'] : spots['A'];
  if (!otherSpot) {
    return false;
  }

  if (isReboundingFromSpot) {
    return false;
  }

  const x = spots[spot].getX();
  const y = spots[spot].getY();
  const newDistance = getDistance(x, y, otherSpot.getX(), otherSpot.getY());

  const isInReboundRange = newDistance < SPOT_REBOUND_THRESHOLD * getScaleFactor();

  if (isInReboundRange) {
    isReboundingFromSpot = true;
    setTimeout(() => isReboundingFromSpot = false, REBOUND_THROTTLE);
  }

  distance[spot] = newDistance;

  return isInReboundRange;
}

function calculatePointerRebound(spot) {
  if (!spots[spot] || isReboundingFromPointer[spot]) {
    return NO_REBOUND;
  }
  const x = spots[spot].getX();
  const y = spots[spot].getY();

  const newDistanceToPointer = getDistance(x, y, pointerPosition.x, pointerPosition.y);
  const isMovingTowardsPointer = newDistanceToPointer < distanceToPointer[spot];

  distanceToPointer = newDistanceToPointer;
  if (newDistanceToPointer > MOUSE_REBOUND_THRESHOLD) {
    return NO_REBOUND;
  }

  isReboundingFromPointer[spot] = true;
  setTimeout(() => isReboundingFromPointer[spot] = false, POINTER_REBOUND_THROTTLE);

  return {
    x: true,
    y: true
  }
}

const isDeccelerating = { X: false, Y: false };
function calculateSpotReboundVelocity(spot, axis, rebound) {
  const velocityKey = `velocity${axis}`;
  const initialVelocityKey = `initialVelocity${axis}`;

  if (!rebound && !isDeccelerating[axis]) {
    return;
  }

  // Start of decceleration
  if (rebound && !isDeccelerating[axis]) {
    isDeccelerating[axis] = true;
  }

  // Reached near zero velocity, start of acceleration
  // if (Math.abs(spot[velocityKey]) - (Math.abs(spot[initialVelocityKey]) * ACCELERATION_FACTOR) === 0) {
  //   isDeccelerating[axis] = false;
  //   isAccelerating[axis] = true;
  // }

  // Reached full inverse velocity
  if (
    Math.abs(spot[velocityKey]) >= Math.abs(spot[initialVelocityKey])
    && spot[velocityKey] > 0 === (spot[initialVelocityKey] * -1) > 0
  ) {
    isDeccelerating[axis] = false;
    spot[initialVelocityKey] = spot[initialVelocityKey] * -1;
    spot[velocityKey] = spot[initialVelocityKey];
    return;
  }

  spot[velocityKey] = spot[velocityKey] - spot[initialVelocityKey] * ACCELERATION_FACTOR;
}

function getPulseAnimationFunc(spot) {
  return frame => {
    const scale = 1 + Math.sin((frame.time * 2 * Math.PI) / PULSE_FREQUENCY) * PULSE_FACTOR;
    spots[spot].scale({ x: scale, y: scale });
  }
}

function getMoveAnimationFunc(spot) {
  return frame => {
    const stageRebound = calculateStageRebound(spot);
    const spotRebound = calculateSpotRebound(spot);

    if (stageRebound.x) {
      spots[spot].velocityX = spots[spot].velocityX * -1;
      spots[spot].initialVelocityX = spots[spot].initialVelocityX * -1;
    }
    if (stageRebound.y) {
      spots[spot].velocityY = spots[spot].velocityY * -1;
      spots[spot].initialVelocityY = spots[spot].initialVelocityY * -1;
    }

    calculateSpotReboundVelocity(spots[spot], 'X', spotRebound);
    calculateSpotReboundVelocity(spots[spot], 'Y', spotRebound);

    spots[spot].setX(spots[spot].getX() + frame.timeDiff * spots[spot].velocityX);
    spots[spot].setY(spots[spot].getY() + frame.timeDiff * spots[spot].velocityY);
  }
}

function drawSpot(spot) {
  const imageObj = new Image();
  // imageObj.src = `images/spot${spot}.svg`;
  imageObj.src = SVG_SPOTS[spot]

  imageObj.onload = () => {
    const { x, y } = getSpotPosition(spot);
    const { width, height } = getSpotDimensions(spot);
    spots[spot] = new Konva.Image({
      x: x + width / 2,
      y: y + height / 2,
      width,
      height,
      offsetX: width / 2,
      offsetY: height / 2,
      image: imageObj,
    });
    spots[spot].velocityX = spots[spot].initialVelocityX = BASE_VELOCITY * getRandomDirection();
    spots[spot].velocityY = spots[spot].initialVelocityY = BASE_VELOCITY * getRandomDirection();

    layer.add(spots[spot]);
    layer.batchDraw();

    pulseAnimation[spot] = new Konva.Animation(getPulseAnimationFunc(spot), layer);
    pulseAnimation[spot].start();
    moveAnimation[spot] = new Konva.Animation(getMoveAnimationFunc(spot), layer);
    moveAnimation[spot].start();
  };
}

function updateSpot(spot) {
  if (!spots[spot]) {
    return;
  }
  const { x, y } = getSpotPosition(spot);
  const { width, height } = getSpotDimensions(spot);

  spots[spot].x(x);
  spots[spot].y(y);
  spots[spot].width(width);
  spots[spot].height(height);
}

function setupCanvas() {
  stage = new Konva.Stage({
    container: 'spots-canvas',
    width: window.innerWidth,
    height: window.innerHeight
  });

  layer = new Konva.FastLayer();

  window.addEventListener('scroll', checkVisibility);
  window.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      stopAnimations()
    } else {
      startAnimations();
    }
  });
}

function startAnimations() {
  if (
    !pulseAnimation['A'] || !pulseAnimation['B']
    || pulseAnimation['A'].isRunning()
  ) {
    return;
  }

  pulseAnimation['A'].start();
  pulseAnimation['B'].start();
  moveAnimation['A'].start();
  moveAnimation['B'].start();
}

function stopAnimations() {
  if (
    !pulseAnimation['A'] || !pulseAnimation['B']
    || !pulseAnimation['A'].isRunning()
  ) {
    return;
  }

  pulseAnimation['A'].stop();
  pulseAnimation['B'].stop();
  moveAnimation['A'].stop();
  moveAnimation['B'].stop();
}

function checkVisibility() {
  if (isVisibleInViewport()) {
    startAnimations();
  } else {
    stopAnimations();
  }
}

function isVisibleInViewport() {
  const viewportTop = window.scrollY;
  const viewportBottom = viewportTop + window.innerHeight;

  const canvas = document.getElementById('spots-canvas');
  if (!canvas) {
    return;
  }
  const canvasTop = canvas.offsetTop;
  const canvasBottom = canvasTop + canvas.offsetHeight;

  return (canvasTop >= viewportTop && canvasBottom <= viewportBottom) || (canvasBottom >= viewportTop && canvasBottom <= viewportBottom);
}

function init() {
  setupCanvas();

  containerDimensions = getContainerDimensions();

  stage.add(layer);
  layer.draw();

  drawSpot('A');
  drawSpot('B');
  setTimeout(checkVisibility, 500);

  stage.on('mousemove', () => {
    pointerPosition = stage.getPointerPosition();
  });
}

function update() {
  stage.width(window.innerWidth);
  stage.height(window.innerHeight);
  stage.draw();

  containerDimensions = getContainerDimensions();

  updateSpot('A');
  updateSpot('B');

  layer.batchDraw();
}

if (!isMobile()){
  init();
  setupResponsiveCallback(update);
}
